import {
  DailyWorkReportKey,
  DailyWorkReportTimeStamp
} from "@/models/dailyWorkReport/DailyWorkReportKey";

export const AssemblyThinwireTableHeaders = [
  { text: "作業年月日", value: "workDate" },
  { text: "更新日時", value: "updateTs" },
  { text: "作業者", value: "worker" },
  { text: "集合番号", value: "assemblyNumber" },
  { text: "種類", value: "conductorType" },
  { text: "構成", value: "composition" },
  { text: "サイズ", value: "size" },
  { text: "集合方向", value: "assemblyDirection" },
  { text: "集合条長", value: "assemblyLength" },
  { text: "本数", value: "wires" },
  { text: "製品異常処置報告", value: "abnormality" },
  { text: "備考", value: "biko" }
] as const;

export class AssemblyThinwireTable {
  workDate: string;
  updateTs: string | null;
  worker: string | null;
  assemblyNumber: string | null;
  conductorType: string | null;
  composition: string | null;
  size: string | null;
  assemblyDirection: string | null;
  assemblyLength: number | null;
  wires: number | null;
  abnormality: string | null;
  biko: string | null;

  constructor(props: {
    workDate: string;
    worker: string | null;
    assemblyNumber: string | null;
    conductorType: string | null;
    composition: string | null;
    size: string | null;
    assemblyDirection: string | null;
    assemblyLength: number | null;
    wires: number | null;
    abnormality: string | null;
    biko: string | null;
  }) {
    this.workDate = props.workDate;
    this.updateTs = null;
    this.worker = props.worker;
    this.assemblyNumber = props.assemblyNumber;
    this.conductorType = props.conductorType;
    this.composition = props.composition;
    this.size = props.size;
    this.assemblyDirection = props.assemblyDirection;
    this.assemblyLength = props.assemblyLength;
    this.wires = props.wires;
    this.abnormality = props.abnormality;
    this.biko = props.biko;
  }
}

export const AssemblyThinwireText = {
  workDate: { text: "作業年月日", value: "workDate" },
  serialNo: { text: "シリアル番号", value: "serialNo" },
  postCode: { text: "部署コード", value: "postCode" },
  machineCode: { text: "機械名", value: "machineCode" },
  worker: { text: "作業者", value: "worker" },
  inspectionFlag: { text: "機械点検記録", value: "inspectionFlag" },
  assemblyNumber: { text: "集合番号", value: "assemblyNumber" },
  conductorType: { text: "種類", value: "conductorType" },
  composition: { text: "構成", value: "composition" },
  size: { text: "サイズ", value: "size" },
  assemblyDirection: { text: "集合方向", value: "assemblyDirection" },
  assemblyLength: { text: "集合条長(m)", value: "assemblyLength" },
  wires: { text: "本数", value: "wires" },
  appearance: { text: "外観", value: "appearance" },
  abnormality: { text: "製品異常処置報告", value: "abnormality" },
  biko: { text: "備考", value: "biko" }
} as const;

export class AssemblyThinwire extends DailyWorkReportKey
  implements DailyWorkReportTimeStamp {
  postCode: string | null;
  machineCode: string | null;
  worker: string | null;
  inspectionFlag: string | null;
  assemblyNumber: string | null;
  conductorType: string | null;
  composition: string | null;
  size: string | null;
  assemblyDirection: string | null;
  assemblyLength: number | null;
  wires: number | null;
  appearance: string | null;
  abnormality: string | null;
  biko: string | null;
  createUser: string | null;
  createTs: number | null;
  updateUser: string | null;
  updateTs: number | null;

  constructor(_props: AssemblyThinwire);
  constructor(_props: DailyWorkReportKey);
  constructor(_props: AssemblyThinwire | DailyWorkReportKey) {
    super(_props);
    if (_props instanceof AssemblyThinwire) {
      this.postCode = _props.postCode;
      this.machineCode = _props.machineCode;
      this.worker = _props.worker;
      this.inspectionFlag = _props.inspectionFlag;
      this.assemblyNumber = _props.assemblyNumber;
      this.conductorType = _props.conductorType;
      this.composition = _props.composition;
      this.size = _props.size;
      this.assemblyDirection = _props.assemblyDirection;
      this.assemblyLength = _props.assemblyLength;
      this.wires = _props.wires;
      this.appearance = _props.appearance;
      this.abnormality = _props.abnormality;
      this.biko = _props.biko;
      this.createUser = _props.createUser;
      this.createTs = _props.createTs;
      this.updateUser = _props.updateUser;
      this.updateTs = _props.updateTs;
      return;
    }
    this.postCode = "SYUSIN";
    this.machineCode = null;
    this.worker = null;
    this.inspectionFlag = "0";
    this.assemblyNumber = null;
    this.conductorType = null;
    this.composition = null;
    this.size = null;
    this.assemblyDirection = null;
    this.assemblyLength = null;
    this.wires = null;
    this.appearance = "0";
    this.abnormality = null;
    this.biko = null;
    this.createUser = null;
    this.createTs = null;
    this.updateUser = null;
    this.updateTs = null;
  }
}
